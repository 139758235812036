import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/top-beijing.png'
import _imports_1 from '@/assets/img/copy.png'


const _hoisted_1 = { style: {"position":"relative"} }
const _hoisted_2 = { style: {"position":"absolute","width":"100%","height":"100%","top":"0.81rem"} }
const _hoisted_3 = { class: "main" }
const _hoisted_4 = { class: "my" }
const _hoisted_5 = { class: "Invitation-code" }
const _hoisted_6 = { class: "num" }
const _hoisted_7 = { class: "friend" }
const _hoisted_8 = { class: "direct" }
const _hoisted_9 = { class: "text" }
const _hoisted_10 = { class: "num" }
const _hoisted_11 = { class: "secondary" }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = { class: "num" }
const _hoisted_14 = { class: "box" }
const _hoisted_15 = {
  class: "add",
  id: "add"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { id: "invite" }

import { onMounted, reactive, ref } from 'vue';
import Top from '@/components/Top/top.vue';
import clipboard3 from 'vue-clipboard3';
import { invitebind, inviteInfo } from '@/api/fetcher';
import message from '@/utils/message';
import { lang } from '@/lang';
import { InviteInfoResp } from '@/api/protocol';


export default /*@__PURE__*/_defineComponent({
  __name: 'invite',
  setup(__props) {

let show = ref(false);
let text = ref<string>('');
const Fill = lang.v.Fill;
const inv_code = lang.v.inv_code;
const user = ref<InviteInfoResp>({
  firstNum: 0,
  parentInvitationCode: '',
  playletShareUrl: '',
  qrCode: '',
  secondNum: 0,
  userInvitationCode: '',
});
const addcode = reactive({
  invitationCode: '',
});
const imgs = {
  img1: require('@/assets/img/add.png'),
  img2: require('@/assets/img/add-user.png'),
};
const supplement = () => {
  if (user.value.parentInvitationCode == null) {
    show.value = true;
    addcode.invitationCode = '';
  } else {
    show.value = false;
  }
};

//解构出复制方法
const Replicating = lang.v.Replicating;
const error3 = lang.v.error3;
const error4 = lang.v.error4;
const { toClipboard } = clipboard3();
const copy = async () => {
  try {
    text.value = user.value.userInvitationCode;
    await toClipboard(text.value);
    message.success(Replicating);
  } catch (error) {
    message.warning(error3);
  }
};
let lianjie = ref('');
const copyInvite = async () => {
  try {
    lianjie.value = user.value.qrCode;
    await toClipboard(lianjie.value);
    message.success(Replicating);
  } catch (error) {
    message.warning(error3);
  }
};
const paste = async () => {
  try {
    await navigator.clipboard.readText();
  } catch (error) {
    message.warning(error4);
  }
};

onMounted(async () => {
  await inviteInfo().then(res => {
    if (res) {
      user.value = res;
    }
  });
});
const warning1 = lang.v.warning1;
const success1 = lang.v.success1;
const confirm = async () => {
  // 如果输入框的数据成功绑定，则显示出来，如果没有，则提示错误
  if (addcode.invitationCode == '') {
    message.error(warning1);
  } else {
    user.value.parentInvitationCode = addcode.invitationCode;
    await invitebind(addcode).then(async res => {
      if (res) {
        message.success(success1);
        user.value.parentInvitationCode = addcode.invitationCode;
        console.log(user.value.parentInvitationCode);
        await inviteInfo().then(res => {
          if (res) {
            user.value = res;
          }
        });
      } else {
        user.value.parentInvitationCode = '';
        return;
      }
    });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_van_dialog = _resolveComponent("van-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Top),
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        style: {"width":"100%","height":"1.52rem"}
      }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(lang).v.invitation), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(user.value?.userInvitationCode), 1),
            _createElementVNode("div", {
              class: "copy",
              onClick: _withModifiers(copy, ["stop"])
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_1,
                alt: ""
              }, null, -1)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(lang).v.directly), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(user.value?.firstNum), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(lang).v.indirect), 1),
            _createElementVNode("div", _hoisted_13, _toDisplayString(user.value?.secondNum), 1)
          ])
        ]),
        _createElementVNode("div", {
          class: "supplement",
          onClick: supplement
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("img", {
                src: user.value.parentInvitationCode === null ? imgs.img1 : imgs.img2,
                alt: ""
              }, null, 8, _hoisted_16)
            ]),
            _createElementVNode("div", {
              class: "text",
              id: "text",
              innerHTML: 
              user.value?.parentInvitationCode == null
                ? _unref(Fill)
                : _unref(inv_code) + user.value?.parentInvitationCode
            ,
              style: _normalizeStyle(
              user.value?.parentInvitationCode == null
                ? ''
                : 'color: rgba(133, 140, 159, 0.50);'
            )
            }, null, 12, _hoisted_17)
          ])
        ]),
        _createElementVNode("div", {
          class: "copyInvite",
          onClick: _withModifiers(copyInvite, ["stop"])
        }, _toDisplayString(_unref(lang).v.Copy_link), 1)
      ])
    ]),
    _createVNode(_component_van_dialog, {
      show: _unref(show),
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_isRef(show) ? (show).value = $event : show = $event)),
      title: _unref(Fill),
      "show-cancel-button": "",
      onConfirm: confirm,
      "confirm-button-color": "rgba(237, 147, 30, 1)"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_18, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((addcode.invitationCode) = $event)),
            placeholder: "Invitation code",
            onPaste: paste
          }, null, 544), [
            [_vModelText, addcode.invitationCode]
          ])
        ])
      ]),
      _: 1
    }, 8, ["show", "title"])
  ], 64))
}
}

})